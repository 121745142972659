import React from 'react'

import {HeaderXl, Body, SmallBody} from 'src/components/text'
import {externalPaths} from 'src/urls'

const ContentDatabasePreamble: React.FunctionComponent = () => (
  <>
    <HeaderXl>Educator Content Library</HeaderXl>
    <Body>
      Just for our Get Schooled Educator Community Members and Partners - we’re
      thrilled to provide you with access to all of our content: hundreds of
      research-based, high-quality, student-friendly articles, videos, and
      materials. Type any keyword in the search bar below to access links to all
      of our website and{' '}
      <a href={externalPaths.youtube} target="_blank" rel="noopener noreferrer">
        YouTube content
      </a>{' '}
      that you can easily share with students and add to your school's website
      and Google classroom pages.
    </Body>
    <Body>
      <b>
        Need help?{' '}
        <a href="mailto:hello@getschooled.com">hello@getschooled.com</a>
      </b>
    </Body>
    <SmallBody mb="4">
      By using our Videos Only search, you agree to comply with the{' '}
      <a
        href={externalPaths.youtubeTerms}
        target="_blank"
        rel="noopener noreferrer"
      >
        YouTube Terms of Service
      </a>
      .
    </SmallBody>
  </>
)
export default ContentDatabasePreamble
